.button {
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;

    :disabled {
        opacity: .3;
    }

    &.primary {
        height: 50px;
        border-radius: 3px;
        border: none;
        background-color: $action-color;
        color: #fff;
    }

    &.secondary {
        height: 30px;
        border-radius: 3px;
        border: solid 2px $action-color;
        background-color: transparent;
        color:  $action-color;
    }

    &.success {
        height: 50px;
        border-radius: 3px;
        border: none;
        color: #fff;
        background-color: #009e00;
    }

    &.danger {
        height: 50px;
        border-radius: 3px;
        border: none;
        color: #fff;
        background-color: #dd4b3a;
    }

    &.transparent {
        background-color: transparent;
        border: none;
    }

    &.link {
        background-color: transparent;
        border: none;
        color: $link-color;
        text-decoration: underline;
    }

    &.delete {
        padding: 0;
        margin: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: transparent;
        border: none;
        height: auto !important;

        img {
            pointer-events: none;
            margin: 0;
            padding: 0;
        }
    }

    &.icon {
        padding: 0;
        margin: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: transparent;
        border: none;
        height: 100% !important;

        img {
            pointer-events: none;
            margin-left: 8px;
            margin-right: 8px;
            padding: 0;
        }
    }

    &.drag {
        padding: 0;
        margin: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        border-radius: 2px;

        img {
            pointer-events: none;
            margin: 0 auto;
            padding: 0;
        }
    }

    &.full {
        width: 100%;
    }

    &.regular {
        font-size: 14px;
        height: 45px;
    }

    &.small {
        font-size: 12px;
        height: 35px;
    }

    &.has-svg:not(.delete) {
        justify-content: space-between;

        img {
            pointer-events: none;
            margin-left: 15px;
        }
    }

    &.disabled {
        background-color: #dadada;
    }

    &:not(.has-svg) {
        text-align: center;
        justify-content: center;
    }

    &.filled {
        background-color: #ffcb8a;
    }
}