.input-group {
    .desc {
        font-size: 12px;
        color: rgb(127, 127, 127);
    }

    .input-file-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 38px;
        border-radius: 3px;
        border: solid $input-border-width $input-label-color;
        padding: 0 10px;
        overflow: hidden;

        img {
            width: 40px;
            height: 70%;
            object-fit: contain;
            object-position: center;
            margin-right: 5px;
        }

        input {
            display: none;
        }

        button {
            display: flex;
            border: none;
            background-color: transparent;
            width: 100%;
            height: 100%;
            align-items: center;
            img {
                padding-right: 15px;
            }
            
            &.loading img {
                width: 45px;
                margin: -8px;
            }

            color: $primary-color;

            font-weight: 500;
            font-size: 13px;
        }
    }
}