.dashboard-pedidos {
    margin-bottom: 25px;

    .os {
        background: #FFF;
        margin-bottom: 20px;
        border-left: solid 3px #E7A03C;

        th, td {
            text-align: left;
            font-size: 12px;
        }

        .os-details {
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: solid 1px #ccc;

            th, td {
                padding-left: 3px;

                &:nth-child(1) {
                    width: 100px;
                }

                &:nth-child(2) {
                    width: 150px;
                }

                &:nth-child(3) {
                    width: 160px;
                }
            }
        }
    }
}