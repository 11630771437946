.indicator {
    &.positive {
        color: blue;
    }
    &.negative {
        color: red;
        font-weight: bold;
    }
}

.modal.product-details .row > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-finance {
    padding-top: 16px;
}

.order-conference .row > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input-group-with-link {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 18px;
    padding-top: 18px;

    a {
        position: absolute;
        bottom: 14px;
        font-size: 14px;
    }
}

.tag-conference-status {
    padding: 6px 8px;
    font-weight: 500;
    color: #fff;
    border-radius: 15px;
    font-size: 12px;
    white-space: nowrap;
    &.pending {
        background-color: red;
    }

    &.conferred {
        background-color: green;
    }
}

.order-product {
    background-color: #fff;
    border: solid 1px $box-border-color;
    // border-left: solid 4px orange;
    border-radius: 3px;
    margin-bottom: $elements-margin;

    .product-statuses {
        padding: 5px;
    }

    .product-details {
        width: 100%;
        padding: 15px 5px;
        display: flex;
        align-items: center;
        border-bottom: solid 1px #ccc;
        
        margin-bottom: 10px;

        .detail {
            display: flex;
            flex-direction: column;
            padding: 0 20px;

            strong {
                color: $text-secondary-color;
                font-weight: 500;
            }
        }

        img {
            object-fit: contain;
            object-position: center;
            width: 45px;
            height: 45px;
        }
    }
}

.approval {
    .approval-count {
        &.success {
            background-color: #009e00;
        }
        &.danger {
            background-color: #dd4b3a;
        }
        &.waiting {
            background-color: #268db6;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding: 10px 0;
        border-radius: 4px;

        strong {
            font-size: 24px;
        }
    }
    

    .approval-group {
        border: solid 1px #ccc;
        background-color: $bg-page;
        border-radius: $border-radius-default;
        padding: 15px;
        margin-top: 20px;

        .approval-footer {
            margin-top: 15px;
        }
    }

    .approval-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 15px 0;
        background-color: white;
        border-radius: $border-radius-default;
        border: solid 1px #ccc;
        min-height: 100%;
        
        h2 {
            font-size: 15px;
            text-align: center;
        }

        h3 {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 20px;
        }

        .cost-title {
            display: inline-block;
            margin-bottom: 15px;
            font-size: 15px;
            font-weight: 500;
        }

        img {
            object-fit: contain;
            object-position: center;
            width: 80px;
            height: 80px;
            margin-bottom: 20px;
        }
    }
}

.status-color {
    font-weight: bold;
    
    &.positive {
        color: #268db6;
    }
    &.negative {
        color: #dd4b3a;
    }
}

.status-tag {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    padding: 5px 15px;
    display: table;
    margin-bottom: 15px;
    border-radius: 20px;
    font-weight: bold;

    &.approved {
        background-color: #009e00;
    }
    &.disapproved {
        background-color: #dd4b3a;
    }
    &.waiting {
        background-color: #268db6;
    }
}