#sidebar {
    height: 100vh;
    width: $sidebar-width;
    background-color: $primary-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ul {
        overflow-y: auto;
        li {
            cursor: pointer;

            .alert {
                font-size: 12px;
                background-color: rgb(175, 10, 10);
                padding: 3px 6px;
                border-radius: 3px;
                position: absolute;
                right: 25px;
            }
            
            a {
                position: relative;
                display: flex;
                padding: 12px;
                color: #fff;
                font-weight: 400;
                font-size: 14px;
                align-items: center;
                transition: .3s;
    
                img {
                    margin-right: 15px;
    
                    &.arrow {
                        position: absolute;
                        right: 15px;
                        transition: .3s;
                    }
                }
            }
    
            ul {
                display: none;
                padding: 10px 0;
                background-color: $secondary-dark-color;
    
                li {
                    a {
                        padding: 6px 20px;
                        .dot {
                            display: table;
                            width: 12px;
                            height: 12px;
                            border: solid 1px $white-color;
                            border-radius: 100%;
                            margin-right: 15px;
                            margin-left: 3px;
                            transition: .3s;
                        }
                    }
    
                    &.active, &:hover {
                        .dot {
                            background-color: $white-color;
                        }
                    }
                }
            }

            &.active {
                .arrow {
                    transform: rotate(-90deg);
                }
                ul {
                    display: block;
                }
            }
        }
    }

    > ul > li {
        &.active, &:hover {
            > a {
                background-color: $secondary-color;
            }
        }
    }

    #user {
        padding: 5px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $secondary-color;

        a {
            color: $white-color;
            font-size: 14px;
        }
    }
}