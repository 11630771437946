.product-statuses {
    table {
        width:100%;
        table-layout: fixed;
        overflow-wrap: break-word;
    }

    th {
        font-size: 14px;
        text-align: left;
        font-weight: 500;
        color: $text-secondary-color;
    }

    .td-status {
        select {
            padding: 8px 0;
            width: 100%;
            border-radius: 3px;
        }
    }
    
    .td-produto {
        display: flex;
        align-items: center;
        width: 100px;
        padding-left: 3px;

        img {
            object-fit: contain;
            object-position: center;
            width: 35px;
            height: 35px;
            margin-right: 3px;
        }

        p {
            margin: 0;
            font-size: 12px;
            text-overflow: ellipsis;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
                    line-clamp: 2; 
            -webkit-box-orient: vertical;
        }
    }
}