.input-group {
    .input-color-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 45px;
        border-radius: 3px;
        border: solid $input-border-width $input-label-color;
        padding: 0 15px;

        > span:last-child {
            width: 100%;
            padding: 0;
            border: none;
            border-radius: $border-radius-default;
            overflow: hidden;
        }
    }
}