.row-nfs {
    display: flex;
}

.col-nfs-list {
    width: calc(100% - 250px);
}

.col-nfs-details {
    width: 230px;
    margin-left: 20px;
    background-color: $white-color;
    padding: 5px 15px;
    border: solid 1px $box-border-color;
    border-radius: 3px;

    h2 {
        margin-top: 10px;
        font-size: 14px;
    }

    ul {
        list-style: none;
        a {
            font-size: 15px;
            text-decoration: none;
            display: table;
            margin: 8px 0;

            &.disabled {
                pointer-events: none;
                color: #ccc;
            }
        }
    }

    .detail {
        padding: 8px 0;

        .title {
            font-size: 14px;
            color: $text-secondary-color;
            width: 100%;
            display: table;
        }

        .value {
            font-size: 16px;
            font-weight: bold;

            &.green {
                color: green;
            }
            &.red {
                color: red;
            }
        }
    }
}