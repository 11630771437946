.input-date {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    margin-top: 5px;
    
    .react-date-picker {
        width: 100%;
        height: 38px;

        .react-date-picker__wrapper {
            padding: 0 8px;
            border-radius: 3px;

            input {
                font-size: 13px;
            }

            span {
                margin-top: -1px;
                font-size: 13px;
            }
        }
    }

    // input {
    //     display: flex;
    //     align-items: center;
    //     width: 100%;
    //     height: 45px;
    //     border-radius: 3px;
    //     border: solid $input-border-width $input-label-color;
    //     padding: 0 15px;

    //     &:disabled {
    //         color: rgb(148, 148, 148);
    //     }
    // }

   

    label {
        z-index: 1;
        font-size: 12px;
        font-weight: $input-label-weight;
        color: $input-label-color;
        position: absolute;
        left: 10px;
        background-color: #fff;
        padding: 0 10px;
        top: -8px;
    }

    .error-message {
        color: red;
        font-size: 12px;
    }

    &.has-error {
        label {
            color: red;
        }
    }
}