.input-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    margin-top: 5px;

    input {
        display: flex;
        align-items: center;
        width: 100%;
        height: 38px;
        border-radius: 3px;
        border: solid $input-border-width $input-label-color;
        padding: 0 10px;
        font-size: 13px;

        &:disabled {
            color: rgb(148, 148, 148);
        }
    }

    &.left {
        input {
            padding-left: 35px;
        }
    }

    label {
        font-size: 12px;
        font-weight: $input-label-weight;
        color: $input-label-color;
        position: absolute;
        left: 10px;
        background-color: #fff;
        padding: 0 10px;
        top: -8px;
    }

    .right {
        position: absolute;
        right: 10px;
        bottom: 5px;
        top: 5px;
        display: flex;
        align-items: center;
        background-color: #FFF;
        padding-left: 5px;

        span {
            font-size: 12px;
            color: $text-secondary-color;
        }
    }

    .left {
        position: absolute;
        left: 10px;
        bottom: 5px;
        top: 5px;
        display: flex;
        align-items: center;
        background-color: #FFF;
        padding-left: 5px;

        span {
            font-size: 12px;
            color: $text-secondary-color;
        }
    }

    .error-message {
        color: red;
        font-size: 12px;
    }

    &.has-error {
        input {
            border-color: red;
        }

        label {
            color: red;
        }
    }
}

.pos-input {
    font-size: 14px;
    display: table;
    margin-top: -10px;
    margin-bottom: 10px;
    color: #6d6d6d;
}